<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <b-row>
          <b-col md="12">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  <!-- {{ $t('relatedAmountList') }} -->
                  ยังไม่ได้ชำระเงิน
                </h3>
              </div>
              <div class="text-center my-2" v-if="!items.length">
                <!-- <img
                  src="@/assets/images/anan-img/svg/doc_empty.svg"
                  alt="empty"
                  height="60px"
                > -->
                <p class="text-muted">
                  No Data
                </p>
              </div>
              <div class="pl-2 pr-2" v-for="(item, index) in items"
              :key="index">
                <h3 class="mt-2">{{ item.data[0].username }}</h3>
                <b-table
                  responsive="sm"
                  :fields="fieldsRelevant"
                  :items="item.data"
                  show-empty
                >
                  <template #empty="">
                    <b-table-empty-slot>
                      <div class="text-center my-2">
                        <!-- <img
                          src="@/assets/images/anan-img/svg/doc_empty.svg"
                          alt="empty"
                          height="60px"
                        >
                        <p class="text-muted">
                          No Data
                        </p> -->
                      </div>
                    </b-table-empty-slot>
                  </template>

                  <template #cell(weight)="data"> 
                    {{ Commas(data.item.weight *data.item.product_amount) }}
                  </template>
                  <template #cell(charge_cue)="data"> 
                    {{ CommasCue(data.item.charge_cue *data.item.product_amount) }}
                  </template>
                  
                </b-table>
              </div>
            </div>
          </b-col>

        </b-row>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BLink,
  BBadge,
  // BFormSelect,
  BFormCheckbox,
  BButton,
  VBTooltip,
  BTable,
  BInputGroup,
  BFormRadio,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import 'vue-context/dist/css/vue-context.css'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    // BFormSelect,
    BLink,
    BFormCheckbox,
    BButton,
    vSelect,
    BTable,
    BInputGroup,
    BFormRadio,
    BOverlay,
    VueImageLightboxCarousel,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      iconClass: {},
      userData: JSON.parse(localStorage.getItem('userData')),

      items: [
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,

    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'lot_name', label: this.$t('productLot'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'lot_order', label: this.$t('sequence'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'postnumber', label: this.$t('parcelCode'), thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-left' },
        { key: 'come_thaiwarehouse', label: this.$t('transactionDate'), thStyle: { width: '10%' } , thClass: 'text-center', tdClass: 'text-center'},
        { key: 'weight', label: this.$t('weight'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'charge_cue', label: this.$t('size'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center' },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async mounted() {
    this.GetData()
    // console.log(this.userData)
  },
  methods: {
    GetData() {
      const params = {
        perPage: this.perPage,
        page: this.currentPage
      }
      this.$http.get('/Paymentuser/getNobilling', { params })
        .then(response => {
          response.data.sort((a, b) => {
          const usernameA = a.data[0].username.toLowerCase();
          const usernameB = b.data[0].username.toLowerCase();
          if (usernameA < usernameB) {
              return -1;
          }
          if (usernameA > usernameB) {
              return 1;
          }
          return 0;
        });
        this.items = response.data
      }).catch((err) => {
        console.log(err)
      });
    },
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-context.scss';
</style>
